<div class="modal-header">
  <h6 class="text-start m-0">{{ title }}</h6>

  <fa-icon
    [icon]="['fas', 'xmark']"
    class="font-20-px"
    role="button"
    (click)="activeModal.close()"
  />
</div>

<div class="modal-body edit-post text-start">
  <!-- <span>{{ message }}</span> -->
  <div class="d-flex align-items-start">
    <div class="d-center align-items-baseline">
      <img
        loading="lazy"
        class="w-40-px h-40-px rounded-3"
        data-src="{{ data?.ProfilePicName }}"
        onerror="this.onerror=null;this.src='/assets/images/avtar/placeholder-user.png';"
        alt="avatar"
      />
    </div>
    <div class="px-3 gap-2 align-items-center w-100 ms-2">
      <div class="d-flex align-items-center position-relative">
        <!-- <input
          placeholder="Reply..."
          class="outline-0 w-100 comment-input c-text"
          [(ngModel)]="commentData.comment"
        /> -->
        <!-- <div
          #parentPostCommentElement
          contenteditable
          placeholder="Comment..."
          class="outline-0 w-100 comment-input c-text"
        ></div> -->
        <!-- class="edit-comment-emoji" -->
        <app-tag-user-input
          [ngClass]="postMediaData?.length < 3 ? 'edit-comment-emoji' : ''"
          placeholder="Post a link or image or file or let's just talk!"
          [value]="postInputValue"
          [isShowEmojis]="true"
          (onDataChange)="onTagUserInputChangeEvent($event)"
        />
        <div class="file-input ms-2" *ngIf="postMediaData?.length < 3">
          <div class="file-upload">
            <label class="file" *ngIf="postMediaData?.length < 3">
              <input
                type="file"
                multiple
                accept="image/*"
                (change)="onPostFileSelect($event)"
              />
              <span
                class="file-custom border-0 d-grid text-center"
                role="button"
              >
                <fa-icon
                  [icon]="['fas', 'image']"
                  class="m-0 font-24-px text-primary"
                />
              </span>
            </label>
            <label class="file" *ngIf="!(postMediaData?.[0]?.imageUrl || selectedImage)">
              <input
                type="file"
                accept="application/pdf"
                (change)="onPostFileSelect($event)"
                [disabled]="postData?.meta?.metaimage"
              />
              <span
                class="file-custom border-0 d-grid text-center"
                role="button"
              >
                <fa-icon
                  [icon]="['fas', 'file']"
                  class="m-0 font-24-px text-primary"
                />
              </span>
            </label>
          </div>
        </div>
      </div>
      <!-- <ng-container *ngIf="postData?.imageUrl || selectedImage">
        <div class="position-relative w-52-px h-52-px mb-2 mt-2">
          <img
            loading="lazy"
            data-src="{{ postData?.imageUrl || selectedImage }}"
            class="w-100 h-100 rounded-4"
            alt="icon"
          />
          <fa-icon
            [icon]="['fas', 'xmark']"
            class="position-absolute top-0 start-100 translate-middle badge bg-danger p-1 font-12-px c-pointer text-white"
            (click)="removePostSelectedFile()"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="pdfName">
        <div class="position-relative w-100-px h-100-px mt-5">
          <fa-icon
            [icon]="['fas', 'file-pdf']"
            class="c-pointer close-icon font-40-px"
          />
          <span class="c-pdf-show">{{ pdfName.replaceAll("%", " ") }}</span>
          <fa-icon
            [icon]="['fas', 'xmark']"
            class="position-absolute top-0 translate-middle badge bg-danger p-1 font-12-px c-pointer c-icon-color"
            role="button"
            (click)="removePostSelectedFile()"
          />
        </div>
      </ng-container> -->
      <ng-container *ngIf="combinedMediaData?.length">
        <ngb-carousel [showNavigationIndicators]="false" [interval]="0"
          [showNavigationArrows]="combinedMediaData.length > 1">
          <ng-container *ngFor="let media of combinedMediaData">
            <ng-template ngbSlide>
              <div class="d-flex select-media-post align-items-center">
                <ng-container *ngIf="media?.imageUrl">
                  <div class="position-relative mt-5">
                    <img
                      loading="lazy"
                      [src]="media?.imageUrl"
                      class="h-152-px w-152-px rounded-4"
                      alt="icon"
                      onerror="this.onerror=null;"
                    />
                    <fa-icon
                      [icon]="['fas', 'xmark']"
                      class="position-absolute top-0 start-100 translate-middle badge bg-danger p-1 font-12-px c-pointer c-icon-color"
                      role="button"
                      (click)="removePostSelectedFile(media, 'image')"
                    />
                  </div>
                </ng-container>
                <ng-container *ngIf="media?.pdfUrl || media?.pdfName">
                  <div class="position-relative mt-5 w-220-px">
                    <fa-icon
                      [icon]="['fas', 'file-pdf']"
                      class="c-pointer close-icon font-40-px"
                    />
                    <span class="c-pdf-show">
                      {{ (media?.pdfUrl || media?.pdfName)?.replace('%', ' ') }}</span>
                    <fa-icon
                      [icon]="['fas', 'xmark']"
                      class="position-absolute top-0 translate-middle badge bg-danger p-1 font-12-px c-pointer c-icon-color"
                      role="button"
                      (click)="removePostSelectedFile(media, 'pdf')"
                    />
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ng-container>
        </ngb-carousel>
      </ng-container>
    </div>
  </div>
</div>

<div class="modal-footer justify-content-end gap-2">
  <button
    type="button"
    class="btn btn-sm btn-danger"
    (click)="activeModal.close('cancel')"
  >
    {{ cancelButtonLabel }}
  </button>

  <button
    type="button"
    class="btn btn-sm btn-secondary"
    (click)="onChangeComment()"
  >
    {{ confirmButtonLabel }}
  </button>
</div>
